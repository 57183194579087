.divider {
  @apply whitespace-nowrap flex flex-1 items-center justify-center;
  &:before,
  &:after {
    @apply w-full;
    height: 0.025rem;
    --tw-content: "";
    content: var(--tw-content);
    background-color: var(--divider-color);
  }
  &.dark {
    &:before,
    &:after {
      background-color: var(--divider-reverse-color);
    }
  }
}
.box {
  padding: 2rem 0;
  .label {
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 2rem;
    // color: var(--black-1);
  }
}

.dividerHorizontal {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  width: 1rem;
  &:before,
  &:after {
    height: 100%;
    width: 0.025rem;
  }
}
