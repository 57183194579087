.section {
  @apply flex justify-between gap-8;
  padding-top: 0;
  margin-top: 0;
  .imageBg {
    @apply flex justify-center items-center rounded-xl;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    background-color: #8ec5fc;
    background-image: var(--card-linear-gradient);
    .title {
      @apply text-3xl text-white;
      font-family: var(--font-family-secondary);
    }
  }
  .image {
    @apply flex;
    aspect-ratio: 16 / 9;
  }
  .left {
    width: 70%;
    .tableContent {
      @apply hidden;
    }
  }
  .right {
    max-width: 30%;
  }
}
@media (max-width: 1023px) {
  .section {
    @apply flex-col-reverse items-center;
    .left {
      width: 100%;
      .tableContent {
        @apply block;
      }
    }
    .right {
      @apply hidden;
      max-width: 35rem;
    }
  }
}
@media (max-width: 576px) {
  .section {
    .image {
      // @apply rounded-none;
    }
  }
}
