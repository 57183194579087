.section {
  @apply relative w-full;
  margin: 2rem;
  &.vertical {
    @apply w-10/12 mx-auto;
  }
  .wrapper {
    @apply relative w-full rounded-2xl overflow-hidden;
  }
  .image {
    // @apply text-black-1;
    margin-top: 2rem;
  }
  .text {
    @apply flex justify-center mt-4 italic;
    color: var(--paragraph-color);
  }
}
