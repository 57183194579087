.section {
  // padding-top: 1rem;
  .paragraph {
    // @apply text-black-1;

    letter-spacing: 0.0313rem;
    margin-bottom: 1rem;
    line-height: 2rem;
  }
}
