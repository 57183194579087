.boxThankPage {
  background-color: var(--bg-course);
  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    text-transform: capitalize;
    margin-bottom: 2rem;
  }
  .content {
    p,
    a {
      margin-bottom: 1rem;
      font-size: 18px;
      line-height: 28px;
    }
    .hightlight {
      font-weight: 500;
    }
  }
}
