.section {
  @apply relative bg-white;
  padding-bottom: 4rem;
  .action {
    @apply flex items-center absolute top-[1rem] right-[1rem] cursor-pointer;
    transition: all 0.2s ease-in-out;
    transform: translateX(4rem);
    .arrow {
      transition: all 0.2s ease-in-out;
    }
    label {
      @apply -mt-[0.125rem] cursor-pointer;
      font-size: 0.875rem;
      transform: scaleX(0);
      transition: all 0.2s ease-in-out;
      transform-origin: 100% 50%;
      color: var(--paragraph-color);
    }
    &.flip {
      transform: translateX(0rem);
      .arrow {
        transform: scaleX(-1);
      }
      label {
        transform: scaleX(1);
      }
    }
  }
  .table {
    @apply flex flex-col gap-4;
    padding: 1.5rem;
    border: 1px solid var(--secondary-color);
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    transform-origin: 100% 50%;
    opacity: 1;
    h6 {
      @apply cursor-pointer;
      font-size: 1rem;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
    }
    &.collapse {
      transform: scaleX(0);
      opacity: 0;
    }
  }
}

[data-theme="dark"] {
  .section {
    background-color: var(--layer-2);
  }
}
