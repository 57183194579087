$time: 10s;
.section {
  .scroller {
    -webkit-mask-image: linear-gradient(
      90deg,
      transparent,
      #fff 20%,
      #fff 80%,
      transparent
    );
    .scrollInner {
      @apply flex gap-8 py-[0.5rem];

      width: max-content;
      overflow: "no-wrap";
      div {
        @apply flex gap-8 items-center;
        span {
          @apply uppercase;
          font-family: var(--font-family-secondary);
          letter-spacing: 0.5rem;
          //   font-size: 5rem;
          //   line-height: 5rem;
          font-size: clamp(2.1775rem, 2.0114rem + 1vw, 5rem);
          line-height: clamp(2.1775rem, 2.0114rem + 1vw, 5rem);
          color: var(--title-article);
          white-space: nowrap;
        }
        &::after {
          content: "";
          display: block;
          width: 1rem;
          height: 1rem;
          //   line-height: 5rem;
          border-radius: 50%;
          background-color: var(--title-article);
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .scroller[data-animated="true"] {
    .scrollInner {
      animation: scroll var(--animation-direction, forwards)
        var(--animation-duration, 100s) linear infinite;
    }
  }
  .scroller[data-direction="right"] {
    --animation-direction: reverse;
  }
  .scroller[data-direction="left"] {
    --animation-direction: forwards;
  }
}

@keyframes scroll {
  to {
    transform: translateX(calc(-50% - 2rem - 10px));
  }
}
