.item {
  color: var(--grey-1);
  &.sub {
    color: var(--medium-grey);
  }
  .circle {
    @apply absolute rounded-full left-[-5px] z-10;
    width: 8px;
    height: 8px;
  }
  &:hover {
    &.sub {
      .circle {
        @apply bg-slate-400;
      }
    }
    &.hoverEffect {
      color: var(--black);
    }
  }
}

[data-theme="dark"] {
  .item {
    color: var(--grey-3);
    &.sub {
      color: var(--grey-2);
    }
    &:hover {
      &.sub {
        .circle {
          @apply bg-slate-600;
        }
      }
      &.hoverEffect {
        color: var(--white);
      }
    }
  }
}
