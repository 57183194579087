.section {
  @apply flex gap-8;
  margin-bottom: 2rem;

  .box {
    @apply bg-white rounded-xl w-1/2;
    padding: 2rem;
    label {
      @apply block mb-4;
      font-family: var(--font-family-secondary);
    }
    p {
      font-size: 1.125rem;
      color: var(--paragraph-color);
    }
    .icon {
      @apply cursor-pointer;
      padding: 1rem;
      border-radius: 100%;
      border: 1px solid rgba(var(--grey-500), 0.12);
      transition: border-color 0.3s ease-in-out;
      svg {
        path {
          transition: fill 0.2s ease-in-out;
        }
      }
      &:hover.fb {
        border-color: #1773ea;
        svg {
          path {
            fill: #1773ea;
          }
        }
      }
      &:hover.ig {
        border-color: #e9336e;
        svg {
          path {
            fill: #e9336e;
          }
        }
      }
      &:hover.gh {
        border-color: #171515;
        svg {
          path {
            fill: #171515;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 800px) {
  .section {
    @apply flex-col gap-8;
    .box {
      @apply w-full;
    }
  }
}
@media (max-width: 576px) {
}
[data-theme="dark"] {
  .section {
    .box {
      background-color: var(--layer-2);
      .icon {
        svg {
          path {
            fill: var(--paragraph-color);
          }
        }
      }
    }
  }
}
