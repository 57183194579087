.section {
  @apply flex items-center gap-[4.25rem];
  padding-top: 4rem;
  .title {
    // @apply text-black-1;
  }
  .content {
    padding-top: 2rem;
    // @apply text-black-1;
  }
  .boxImg {
    @apply rounded-2xl overflow-hidden;
    .image {
      width: 100%;
      aspect-ratio: 468 / 400;
    }
  }
  &.left {
    @apply flex-row-reverse;
  }
  &.right {
  }
}

// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
@media (max-width: 576px) {
  .section {
    @apply flex-col-reverse;
    .boxImg {
      width: 100%;
    }
    &.left {
      @apply flex-col-reverse;
    }
  }
}
