.section {
  @apply flex justify-center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  h1 {
    font-family: var(--font-family-secondary);
    text-transform: uppercase;
    letter-spacing: 0.375rem;
  }
}
