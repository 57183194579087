.section {
  // background-color: white;
  padding-bottom: 10rem;
  .badge {
    @apply px-4 py-2;
    background-color: #e6e8eb;
    border-radius: 1rem;
    color: #4d5764;
  }
  .boxEmail {
    @apply bg-gray-400;
    img {
      filter: invert(100%) sepia(0%) saturate(7492%) hue-rotate(159deg)
        brightness(105%) contrast(102%);
    }
  }
}
[data-theme="dark"] {
  .section {
    .contact {
      color: var(--paragraph-color);
    }
    .boxEmail {
      background: -webkit-linear-gradient(
        315deg,
        var(--gradient-cotton-candy-from),
        var(--gradient-cotton-candy-to)
      );
      img {
        filter: invert(100%) sepia(0%) saturate(7492%) hue-rotate(159deg)
          brightness(105%) contrast(102%);
      }
    }
  }
}
