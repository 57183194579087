.checkbox {
  input {
    @apply w-6 h-6 appearance-none bg-white outline-none rounded-5 grid place-content-center  content-center cursor-pointer;
    &::before {
      content: url("../../../gstyles/styleguide/icons/svgs/checkmark-square-none.svg");
      @apply h-6 opacity-90;
    }
    &:hover {
      @apply bg-gray-100;
    }

    &:disabled {
      @apply bg-white cursor-default;
      &::before {
        content: url("../../../gstyles/styleguide/icons/svgs/checkmark-square-none.svg");
        @apply h-6 opacity-70;
      }
    }

    &:checked {
      @apply w-6 h-6;
      &::before {
        content: url("../../../gstyles/styleguide/icons/svgs/checkmark-square-active.svg");
        @apply h-6 opacity-100;
      }
    }
  }
  input.dark {
    background-color: transparent !important;
    @apply w-6 h-6 appearance-none bg-white outline-none rounded-5 grid place-content-center  content-center cursor-pointer;
    &::before {
      content: url("../../../gstyles/styleguide/icons/svgs/checkmark-square-none-white.svg");
      @apply h-6 opacity-90;
    }
    &:hover {
      @apply bg-gray-100;
    }

    &:disabled {
      @apply bg-white cursor-default;
      &::before {
        content: url("../../../gstyles/styleguide/icons/svgs/checkmark-square-none.svg");
        @apply h-6 opacity-70;
      }
    }

    &:checked {
      @apply w-6 h-6;
      &::before {
        content: url("../../../gstyles/styleguide/icons/svgs/checkmark-square-active.svg");
        @apply h-6 opacity-100;
      }
    }
  }
}
