$bg-color: #2e281f;
$hl-color: #ae905e;
$muted-color: mix(white, $bg-color, 20%);
$trans-time: 300ms;
$width: 320px;

.group {
  position: relative;
  margin: 0.5rem 0;
  textarea {
    // resize: none;
  }
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  .required {
    @apply ml-1;
    color: var(--input-hl-color);
  }
  .dropdown,
  textarea,
  input {
    background: none;
    color: var(--input-text-color);
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--input-bg-color);
    &:focus {
      outline: none;
    }
    &:focus ~ label {
      // &:valid ~ label {
      top: -1rem;
      font-size: 12px;
      color: var(--input-hl-color);
    }
    &:focus ~ .bar:before {
      width: 100%;
    }
  }
  textarea.dark,
  input.dark {
    // border-bottom: 1px solid rgba(#f7eedf, 0.3);
    // color: var(--primary-color);
  }

  // &.active {
  //   & > label {
  //     top: -1rem;
  //     font-size: 12px;
  //     color: var(--input-hl-color);
  //   }
  // }
  &.valid {
    & > label {
      top: -1rem;
      font-size: 12px;
      color: var(--input-hl-color);
    }
    & > label.dark {
      color: var(--input-hl-color);
    }
  }
  &.textArea {
    padding-top: 1rem;
  }
  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: "";
      height: 1px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: var(--input-hl-color);
      transition: $trans-time ease all;
      left: 0%;
    }
  }
  & > label {
    color: var(--input-text-color);
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: $trans-time ease all;
  }
  & > label.dark {
    color: var(--primary-color);
  }
  .error {
    @apply absolute;
    font-size: 0.75rem;
    bottom: -1.5rem;
    color: var(--input-error-color);
    font-style: italic;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
