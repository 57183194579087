.subscribeForm {
  .error {
    @apply mt-4;
    color: var(--primary-price-color);

    bottom: -1.7rem;
  }
}
.notification {
  .info {
    @apply absolute;
    bottom: -2.2rem;
  }
}
