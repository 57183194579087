.section {
  @apply flex flex-col h-full;
  // @apply rounded;
  // border: 1px solid rgba(var(--grey-500), 0.12);
  padding: 1rem;
  .imageBox {
    padding-bottom: 1rem;
  }
  .imageBg {
    @apply flex justify-center items-center rounded-md;
    aspect-ratio: 19 / 10;
    background-image: var(--card-linear-gradient);
    .title {
      @apply text-2xl text-white;
      font-family: var(--font-family-secondary);
    }
  }
  .image {
    @apply flex rounded-md;
    aspect-ratio: 19 / 10;
  }
  .category {
    font-weight: 300;
    font-size: 0.875rem;
    color: var(--secondary-color);
  }
  .title {
    margin-bottom: 0.5rem;
  }
  .description {
    // margin-bottom: 2rem;
    color: var(--paragraph-color);
    // color: var(--dark-grey);

    font-size: 1.125rem;
    font-weight: 300;
  }
}

@media (max-width: 800px) {
  .section {
    .description {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 576px) {
  .section {
    padding: 1rem;
    .imageBox {
      padding-bottom: 1rem;
    }
    .title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 0.5rem;
    }
    .description {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
