.section {
  @apply flex flex-col gap-4 relative bg-white overflow-hidden;
  margin: 0 auto 2.5rem auto;
  padding: 1.5rem;
  border: 1px solid var(--secondary-color);
  border-radius: 0.25rem;
  max-width: 28.75rem; // .action {
  //   @apply flex justify-between cursor-pointer;
  //   transition: all 0.2s ease-in-out;
  //   .arrow {
  //     @apply cursor-pointer;
  //     transition: all 0.2s ease-in-out;
  //   }
  //   label {
  //     font-size: 1rem;
  //     transition: all 0.2s ease-in-out;
  //     transform-origin: 50% 0%;
  //   }
  // }
  .table {
    @apply flex flex-col gap-4;
    h6 {
      @apply cursor-pointer;
      font-size: 1rem;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}
[data-theme="dark"] {
  .section {
    background-color: var(--layer-2);
  }
}
