.section {
  background-color: white;
  padding-bottom: 2rem;
  .badge {
    @apply px-4 py-2;
    background-color: #e6e8eb;
    border-radius: 1rem;
    color: #4d5764;
  }
  .skills {
    @apply grid grid-cols-5 gap-12;
    .item {
      @apply flex items-center justify-center;
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
  .section {
    .skills {
      @apply grid-cols-3;
    }
  }
}
@media (max-width: 576px) {
  .section {
    .skills {
      @apply grid-cols-2;

      // grid-template-columns: repeat(3, 1fr);
    }
  }
}
[data-theme="dark"] {
  .section {
    background-color: var(--layer-2);
  }
}
