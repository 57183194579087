.section {
  background-color: white;
  padding-bottom: 2rem;
  .badge {
    @apply px-4 py-2;
    background-color: #e6e8eb;
    border-radius: 1rem;
    color: #4d5764;
  }
  .support {
    @apply flex justify-center gap-8;
    width: 80%;
    .item {
      @apply flex flex-col items-center justify-center;
      width: 12.5rem;
      .img {
        width: 100%;
        max-width: 12.5rem;
        aspect-ratio: 1 / 1;
      }
      label {
        @apply block mt-4;
        font-family: var(--font-family-secondary);
        font-size: 1.375rem;
        font-weight: 500;
      }
      &.momo {
        color: #a50064;
      }
      &.acb {
        color: #0038a8;
      }
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
  .section {
  }
}
@media (max-width: 576px) {
  .section {
  }
}
[data-theme="dark"] {
  .section {
    background-color: var(--layer-2);
  }
}
