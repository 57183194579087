.section {
  .box {
    @apply grid grid-cols-3 gap-8;
  }
  .head {
    padding-bottom: 2rem;
  }
  // .list {
  //   @apply w-2/5;
  //   .head {
  //     padding-bottom: 2rem;
  //   }
  //   .content {
  //     @apply flex flex-col gap-4;
  //   }
  // }
}

@media (max-width: 1200px) {
  .section {
    .box {
      @apply w-full grid-cols-2;
      // }
      // .list {
      //   @apply block w-full;
      // }
    }
  }
}
@media (max-width: 768px) {
  .section {
    .box {
      @apply grid-cols-1;
    }
    padding-top: 1rem;
    // .box {
    // padding: 1rem;
  }
}
