.section {
  padding-bottom: 0;
  margin-bottom: 0;
  .image {
    @apply rounded-xl;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
  .title {
    // padding-top: 2rem;
    @apply font-medium;
    text-shadow: 1px 0px 1px var(--grey-2);
  }
  .description {
    color: rgb(var(--grey-700));

    letter-spacing: 0.0313rem;
    line-height: 2rem;
    font-style: italic;
  }
}
[data-theme="dark"] {
  .section {
    .description {
      color: var(--paragraph-color);
    }
  }
}
